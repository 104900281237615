import { lazy } from "react";
import { ADMIN, ASISTENTE_MEDICO, FARMACIA, MEDICO, SYSTEM } from "./permisson";

export const publicRoutes = [
  {
    path: "login",
    Component: lazy(() => import("./login/LoginPage")),
  },
];

export const privateRoutes = [
  {
    key: "agenda",
    path: "agenda",
    access: [ASISTENTE_MEDICO, MEDICO, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/diary/DiaryPage")),
  },
  {
    key: "pacientes",
    path: "pacientes",
    access: [ASISTENTE_MEDICO, MEDICO, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/record/RecordPage")),
  },
  {
    key: "pacientes-detalle",
    path: "pacientes/detalle",
    access: [ASISTENTE_MEDICO, MEDICO, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/recordDetail/RecordDetailPage")),
  },
  {
    key: "maquinas",
    path: "maquinas",
    access: [SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/machine/MachinePage")),
  },
  {
    key: "productos",
    path: "productos",
    access: [FARMACIA, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/product/ProductPage")),
  },
  {
    key: "usuarios",
    path: "usuarios",
    access: [SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/users/UsersPage")),
  },
  {
    key: "doctores",
    path: "doctores",
    access: [SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/doctor/DoctorPage")),
  },
  {
    key: "inventarios",
    path: "inventarios",
    access: [FARMACIA, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/inventory/InventoryPage")),
  },
  {
    key: "inventarios-detalle",
    path: "inventarios/detalle",
    access: [FARMACIA, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/InventoryDetail/InventoryMachinePage")),
  },
  {
    key: "dev",
    path: "desarrollo",
    access: [SYSTEM],
    Component: lazy(() => import("../pages/developer/DeveloperPage")),
  },
  {
    key: "recetas",
    path: "recetas",
    access: [MEDICO, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/prescription/PrescriptionPage")),
  },
  {
    key: "ordenes",
    path: "ordenes",
    access: [MEDICO, SYSTEM, ADMIN],
    Component: lazy(() => import("../pages/order/OrderPage")),
  },
];