import React from "react";

import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useAuth } from "../auth";

export const Page404 = () => {
  const { urlLogIn } = useAuth();
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <div className="text-center mt-5">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Pagina no encontrada.
          </p>
          <p className="lead">Verifica que la pagina que buscas exista.</p>
          <Link to={urlLogIn} className="btn btn-primary">
            regresar al inicio
          </Link>
        </div>
      </div>{" "}
    </>
  );
};

export const Loading = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <div className="text-center mt-5">
          <h1 className="display-1 fw-bold mt-5">Cargando...</h1>
          <p className="fs-3">
            <span className="text-success">
              Un Momento! La pagina que intentas acceder no está lista, por espera por favor
            </span>
          </p>
          <h1 className="display-1 fw-bold mt-5">
            <Spinner
              color="primary"
              className="mt-3"
              style={{
                height: "8rem",
                width: "8rem",
              }}
            />
          </h1>
        </div>
      </div>
    </>
  );
};

export function validateInputs(text, min = 0) {
  if (typeof text === "undefined" || text === "0") {
    return true;
  }
  if (min > 0 || min < 0) {
    return Number(text) < min;
  }
  return text !== "" ? false : true;
}

const optionsData = {
  weekday: "long",
  year: "numeric",
  day: "numeric",
  month: "long",
};

export const getFormatDate = (month) => {
  if (month <= 9) {
    return "0" + month;
  }
  return month;
};

function getHours(date) {
  return (
    getFormatDate(date.getHours()) + ":" + getFormatDate(date.getMinutes()) + ":" + getFormatDate(date.getSeconds())
  );
}

function getTime(date) {
  const current = getHours(date);
  const hora = current === "18:00:00" ? getHours(new Date()) : current;
  const fecha = date.toLocaleDateString("es-GT", optionsData);
  return fecha + ", " + hora;
}

export function setDate(date, type = 0) {
  const fecha = new Date(date);
  if (type === -1) return getTime(fecha);
  if (type > 0) delete optionsData.weekday;
  if (type > 1) optionsData.month = "numeric";
  return fecha.toLocaleDateString("es-GT", optionsData);
}

export function createDate(date = null) {
  let fecha = new Date();
  if (date !== null) {
    fecha = new Date(date);
  }
  return (
    fecha.getFullYear() +
    "-" +
    getFormatDate(fecha.getMonth() + 1) +
    "-" +
    getFormatDate(fecha.getDate()) +
    "T" +
    getHours(fecha) +
    "Z"
  );
}

export function inputDate(date) {
  let fecha = new Date(date);
  return fecha.getFullYear() + "-" + getFormatDate(fecha.getMonth() + 1) + "-" + getFormatDate(fecha.getDate());
}

export function calcularEdad(dateString) {
  let hoy = new Date();
  let nacimiento = new Date(dateString);

  let dias = (hoy - nacimiento) / 1000 / 60 / 60 / 24;
  let meses = parseInt((dias % 365) / 30);
  let anios = parseInt(dias / 365);
  dias = parseInt((dias % 365) % 30);

  return anios + " años " + meses + " meses " + dias + " días.";
}

//Get Info product
export const getProduct = (productsList, idProduct) => {
  let data = productsList.filter((dato) => String(dato.idProduct) === String(idProduct));
  const error = {
    sku: "N/A",
    name: "No encontrado",
    description: "No encontrado",
    active: false,
  };
  return data.length === 0 ? error : data[0];
};

export const Permissions = () => {
  const { user } = useAuth();
  return user ? true : false;
  /*
  if (user.authorities !== undefined) {
    return user.authorities[0].name === "ADMIN" ? true : false;
  }
  return false;
  */
};

export const validateText = (text) => {
  return text === null ? "" : text;
};

export const showCategories = (categories) => {
  const first = (data) => {
    return (
      <>
        <option key="0" value="0" disabled>
          Elija una categoría disponible...
        </option>
        {data}
      </>
    );
  };
  const data = categories.map((item) => {
    if (item.enabled) {
      return (
        <option key={item.idCategory} value={item.idCategory}>
          {item.name}
        </option>
      );
    }
    return "";
  });
  return first(data);
};

export function getCategory(id, categories) {
  let category = categories.filter((item) => {
    return item.idCategory === id;
  });
  return category.length !== 0 ? category[0].name : "Algo a ido mal";
}

export const showMachines = (machines) => {
  const first = (data) => {
    return (
      <>
        <option key="0" value="0" disabled>
          Elija una máquina disponible...
        </option>
        {data}
      </>
    );
  };
  const data = machines.map((item) => {
    if (item.enabled) {
      return (
        <option key={item.idMachine} value={item.idMachine}>
          {item.name}
        </option>
      );
    }
    return "";
  });
  return first(data);
};

export const showUsers = (user) => {
  const first = (data) => {
    return (
      <>
        <option key="0" value="0" disabled>
          Elija un doctor disponible...
        </option>
        {data}
      </>
    );
  };
  const data = user.map((item) => {
    const user = item.firstName + " " + item.lastName;
    return (
      <option key={item.id} value={user}>
        {user}
      </option>
    );
  });
  return first(data);
};

/*
export const getSetting = (settings, name, type, defaultValue = 0) => {
  let data = settings.filter((dato) => dato.name === name);
  let error = "";
  if (typeof type === "string") {
    const value = defaultValue === 0 ? "No Encontrado" : defaultValue;
    error = {
      new: true,
      name: name,
      data: value,
      description: value,
    };
  }
  if (typeof type === "number") {
    const value = defaultValue === 0 ? 12345 : defaultValue;
    error = {
      new: true,
      name: name,
      data: value,
      description: value,
    };
  }
  return data.length === 0 ? error : data[0];
};
*/

export function mayusculas(texto) {
  let palabras = texto.trim().split("&");
  const response = palabras
    .map((palabra) => {
      if (palabra === "") return "";
      return palabra[0].toUpperCase() + palabra.substring(1).toLowerCase();
    })
    .join(" ");
  return response;
}

export function onlyNumbers(text) {
  if (typeof text === "undefined") {
    return "";
  }
  return String(text).replace(/\D/, /./g, "");
}
