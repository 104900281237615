import axios from "axios";
import { getHeaders, getUrl, errorAxios } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createClinicalHistoryAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/clinicalHistory/save`,
    data: formData,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readClinicalHistoryAPI = async (formData) => {
  const config = {
    method: "GET",
    url: `${getUrl}/clinicalHistory/getAll?idRecord=${formData}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ITEM
export const updateClinicalHistoryAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/clinicalHistory/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE DELETE ITEM
export const deleteClinicalHistoryAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/clinicalHistory/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ITEM
export const itemClinicalHistoryAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/clinicalHistory/getById/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
