import React, { lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Page404 } from "../functions/Pages";
import { urlLogOut } from "./../settings";
import { privateRoutes, useAuth } from "./index";

const ProtectedLayout = lazy(() => new Promise((resolve) => {
  resolve(import("./layout/protectedLayout"))
}));

export const ProtectedRoute = () => {
  const [user, setUser] = useState();
  const { user: userInfo } = useAuth();

  useEffect(() => {
    if (!userInfo) return <Navigate to={urlLogOut} />;
    setUser(userInfo);
  }, [userInfo]);


  return (
    <Routes>
      <Route path="" element={<Navigate to="/login" replace />} />
      {privateRoutes.map(({ key, path, access, Component }) => {

        let aproved = false;
        user?.profile?.roles?.forEach(element => {
          const foundAuthority = access?.find(item => item?.idAuthority === element?.idAuthority);
          if (foundAuthority !== undefined) {
            aproved = true;
          }
        });
        if (!aproved) {
          return (
            <Route key={key} path={`/${path}`} element={<ProtectedLayout />}>
              <Route key={key} path={`/${path}`} element={<Page404 />} />
            </Route>
          )
        }

        return (
          <Route key={key} path={`/${path}`} element={<ProtectedLayout />}>
            <Route key={key} path={`/${path}`} element={<Component />} />
          </Route>
        );
      })}
      <Route path="/app/*" element={<Page404 />} />
    </Routes>
  );
};
