import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";

//MEMBER
export const readKioskMemberAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    url: `${getUrl}/kiosks/members/getAll${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const updateKioskMemberAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/kiosks/members/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const deleteKioskMemberAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/kiosks/members/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const itemKioskMemberAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/kiosks/members/getById/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//Record List
export const readKioskRecordListAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    url: `${getUrl}/kiosks/recordsList/getAll${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const updateKioskRecordListAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/kiosks/recordsList/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const deleteKioskRecordListAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/kiosks/recordsList/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const itemKioskRecordListAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/kiosks/recordsList/getById/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
