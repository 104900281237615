import axios from "axios";
import { getHeaders, getUrl, errorAxios } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createEvolutionNoteAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/evolutionNote/save`,
    data: formData,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readEvolutionNoteAPI = async (formData) => {
  const config = {
    method: "GET",
    url: `${getUrl}/evolutionNote/getAll?idRecord=${formData.idRecord}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ITEM
export const updateEvolutionNoteAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/evolutionNote/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE DELETE ITEM
export const deleteEvolutionNoteAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/evolutionNote/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ITEM
export const itemEvolutionNoteAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/evolutionNote/getById/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
